import * as React from "react";
import Layout from "../components/layout";
import {styles} from "../shared-styles";

const ServicesPage = () => {
  return (
    <Layout pageTitle="Servicii">
      <section style={styles.ContentSection}>
        <p>În conformitate cu prevederile art. 3 din legea nr. 51/1995, activitățile prestate de către cabinetele noastre,
          sunt următoarele:</p>
        <ul>
          <li>consultații şi cereri cu caracter juridic;</li>
          <li>asistență şi reprezentare juridică în fața instanțelor judecătorești, a organelor de urmărire penală, a
            autorităților cu atribuții jurisdicționale, a notarilor publici şi a executorilor judecătorești, a organelor
            administrative publice şi a instituțiilor, precum şi a altor persoane juridice, în condițiile legii;
          </li>
          <li>redactarea de acte juridice, atestarea identității părților, a conținutului şi a datei actelor prezentate
            spre autentificare;
          </li>
          <li>asistarea şi reprezentarea persoanelor fizice sau juridice interesate în fața altor autorități publice cu
            posibilitatea atestării identității părților, a conținutului şi a datei actelor încheiate;
          </li>
          <li>apărarea şi reprezentarea cu mijloace juridice specifice a drepturilor şi intereselor legitime ale
            persoanelor fizice şi juridice în raporturile acestora cu autoritățile publice, cu instituțiile şi cu orice
            persoană română sau străină;
          </li>
          <li>activități de mediere;</li>
          <li>activități fiduciare desfășurate în condițiile Codului civil;</li>
          <li>stabilirea temporară a sediului pentru societăți comerciale la sediul profesional al avocatului şi
            înregistrarea acestora, în numele şi pe seama clientului, a părților de interes, a părților sociale sau a
            acțiunilor societăților astfel înregistrate;
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default ServicesPage;
